<template>
  <van-popup
      v-bind:show="show"
      position="bottom"
      class="dialog"
      :style="{ height: '90%', background: '#f5f5f5' }"
      @closed="$emit('update:show',false)"
  >
    <div class="main">
      <div class="b1"><b>商品预览</b></div>
      <div ref="chat" class="chat-list">
        <div v-for="(item,index) in list" :key="index" class="item">
          <div class="chat self">
            <div><div class="img-box"><img v-if="avatar" :src="avatar"></div> </div>
            <div>
              <msg-box :data="item" />
            </div>
          </div>
        </div>
        <div class="x-padding-bottom" />
      </div>
      <div class="p-footer h-end">
        <button class="btn auto gray" @click="show=false">关闭</button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import msgBox from '@/components/msg-box/index.vue'
import img from '@/assets/miniapp-icon.png'
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  components: { msgBox },
  props: {
    id: {
      type: Number,
      default: 0
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      list: [
        /*{
          msgType: 2013,
          isAtNewcomer: 0,	// at
          msgGroupNum: 0,	// integer($int32) 消息组号
          msgSort: 0, //	integer($int32) 消息排序数字（小到大升序）
          // msgType: 2001, //	integer($int32) 消息类型（ 2001 文字 、2002 图片、 2003 语音(只支持amr格式)、 2004 视频 、2005 链接、 2006 好友名片、 2010 文件 、2013 小程序、 2016 音乐、2007动画表情）
          msgContent: '123', //	string  图片、文件、音频、链接图片URL、内容
          msgHref: '123', //	string 视频或者链接URL
          msgTitle: '123',	// string 文件或者链接标题
          msgDes: '123',	// string 链接描述
          msgDurationTime: 0, //	integer($int32) 音频或者视频时长（单位：秒）
          logo: img
        }*/
      ],
      icon: img
    }
  },
  computed: {
    ...mapGetters(['robotInfo']),
    avatar() {
      return this.robotInfo.headImgUrl
    }
  },
  watch: {
    id(v) {
      this.getData()
    }
  },
  created() {

  },
  methods: {
    getData() {
      this.$get('/PushProduct/GetPushProductMsgs', {
        uGId: this.id
      }).then(res => {
        this.list = res || []
        this.list = this.list.map(i => {
          const res = { ...i }
          if (i.msgType === 2013) {
            res.logo = img
            res.href = i.msgDes
            res.msgDes = ''
          }
          return res
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
