<template>
  <div class="page">
    <div class="s1 p-card m-3">
      <div class="flex p-3 fs-16 pointer" @click="dialog3=true">
        <div class="flex-1">推品时间段</div>
        <div><b>{{ timeRange[0] }}-{{ timeRange[1] }}</b></div>
        <div class="pl-1"><div class="p-icon-arrow" style="position:relative;top: -.01rem" /></div>
      </div>
    </div>
    <div class="s2 p-card m-3">
      <div class="flex p-3 fs-16 pointer" @click="goEdit">
        <div class="flex-1">今日推品（{{ list.length }}）</div>
        <div class="pl-1"><div class="p-icon-arrow" /></div>
      </div>
      <div class="divider-dashed ml-3" />
      <div class="b2">
        <no-data v-if="list.length===0" title="暂无推品数据" :show-button="false" />
        <van-list v-model:loading="loading" :finished="finished" finished-text="" @load="getData2">
          <div v-for="(item,index) in list" :key="index" class="item pl-3">
            <div><div class="img"><img :src="item.pushImg"><div class="tag"><span>{{ index+1 }}</span></div></div></div>
            <div class="flex-1">
              <div class="p1"><b>{{ item.pushTitile }}</b></div>
              <div class="p2">{{ item.pushDes }}</div>
              <div class="p3"><span>{{ item.pushDate }} {{ item.pushDateTime }} 推送</span></div>
            </div>
            <div class="p4 pointer ml-2" @click="previewClick(item)">
              <div class="icon" />
              <div><u class="color-primary">预览</u></div>
            </div>
          </div>
        </van-list>
      </div>
    </div>

    <chat-list :id="id" v-model:show="dialog1" />

    <van-popup v-model:show="dialog3" position="bottom" class="dialog3" :style="{background: 'none'}">
      <div class="main">
        <van-picker show-toolbar title="开启时间" :columns="columns" @cancel="dialog3=false" @confirm="dialog3Hide" />
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import chatList from './components/chatList/index.vue'
import { VueDraggableNext } from 'vue-draggable-next'
export default {
  name: 'Index',
  components: { chatList },
  data() {
    return {
      dialog1: false,
      timeRange: ['08:30', '12:00'], // 开始/结束时间
      dialog3: false,
      columns: [],
      list: [],
      loading: false,
      finished: false,
      pageIndex: 1,
      pageSize: 15,
      id: 0
    }
  },
  computed: {
    ...mapGetters(['globalData'])
  },
  created() {
    this.createPicker() // 初始化时间组件
    this.getData()
  },
  methods: {
    getData() {
      this.$get('/PushProduct/GetUserPushTimeRange').then(res => {
        this.timeRange = [res.startTimeRange, res.endTimeRange]
        this.createPicker() // 初始化时间组件
      })
    },
    getData2(reset) {
      this.loading = true
      let url = '/PushProduct/GetUserTodayPushGroupPage'
      if (reset) {
        url = '/api/PushProduct/GetBizTodayPushGroupPage'
        this.pageIndex = 1
      }
      this.$post(url, {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        sortName: '',
        sortOrder: 0
      }).then(res => {
        this.pageIndex++
        const list = res.dataList || []
        if (reset) {
          this.list = list
        } else {
          this.list = [...this.list, ...list]
        }
        if (this.list.length >= res.recordCount) {
          this.finished = true
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
        this.finished = true
      })
    },
    previewClick(item) {
      this.id = item.gId
      this.dialog1 = true
    },
    goEdit() {
      this.$store.commit('setGlobalData', {
        goods: this.list
      })
      this.$router.push('/todayPushEdit')
    },
    createPicker() {
      const h = []
      const m = []
      let index1 = 8
      let index2 = 30
      let index3 = 8
      let index4 = 30
      const addZero = function(n) {
        return n < 10 ? '0' + n : n
      }
      for (let i = 0; i < 24; i++) {
        h.push({
          text: addZero(i) + '时',
          value: i
        })
      }
      for (let i = 0; i < 60; i++) {
        m.push({
          text: addZero(i) + '分',
          value: i
        })
      }
      if (this.timeRange.length) {
        const t1 = this.timeRange[0].split(':')
        const t2 = this.timeRange[1].split(':')
        index1 = Number(t1[0])
        index2 = Number(t1[1])
        index3 = Number(t2[0])
        index4 = Number(t2[1])
      }
      this.columns = [
        {
          values: h,
          defaultIndex: index1
        },
        {
          values: m,
          defaultIndex: index2
        },
        {
          values: ['至'],
          defaultIndex: 0
        },
        {
          values: h,
          defaultIndex: index3
        },
        {
          values: m,
          defaultIndex: index4
        }
      ]
    },
    dialog3Hide(e) {
      const addZero = function(n) {
        return n < 10 ? '0' + n : n
      }
      this.timeRange = [addZero(e[0].value) + ':' + addZero(e[1].value), addZero(e[3].value) + ':' + addZero(e[4].value)]
      this.$Toast.loading({
        message: '提交中...',
        forbidClick: true
      })
      this.$post('/PushProduct/SetUserPushTimeRange', {
        startTimeRange: this.timeRange[0],
        endTimeRange: this.timeRange[1]
      }).then(res => {
        this.dialog3 = false
        this.$Toast('修改成功')
        this.$Toast.clear()
      }).catch(e => {
        this.$Toast.clear()
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import "index";
</style>
